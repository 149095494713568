import React from "react";

// components
import ProjectHeader from "../components/ProjectHeader";

// mui
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";

// styles
import { tableTheme } from "../styles/TableTheme";

// util
import { tableHeaders } from "../util/localDB";
import { get } from "lodash";

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }).toLowerCase();
  return { date: formattedDate, time: formattedTime }; // Return an object with separate date and time
};

const LogTable = (props) => {
  return (
    <ThemeProvider theme={tableTheme}>
      <Table>
        <TableHead />

        {props.data.map((project) => {
          return (
            <TableBody key={project.project.id}>
              <TableRow>
                <ProjectHeader
                  name={project.project.name}
                  width={tableHeaders.length}
                />
              </TableRow>
              <TableRow>
                {tableHeaders.map((header) => {
                  return (
                    <TableCell
                      key={header.value}
                      align="center"
                      style={{ padding: "1em" }}
                    >
                      <b>{header.label}</b>
                    </TableCell>
                  );
                })}
              </TableRow>

              {project.logs.map((log) => {
                return (
                  <TableRow key={log.id}>
                    {tableHeaders.map((header) => {
                      return (
                        <TableCell
                          key={header.value}
                          style={{
                            color: "#454545",
                            textAlign:
                              header.label === "DOWNLOAD"
                                ? "center"
                                : "inherit",
                            //Sets the minimum width for all columns except for "Contents"
                            //Contents will grow/shrink as needed
                            minWidth:
                              header.label !== "CONTENTS" ? "115px" : "inherit",
                            maxWidth:
                              header.label !== "CONTENTS" ? "115px" : "inherit",
                            width:
                              header.label !== "CONTENTS" ? "115px" : "inherit",
                          }}
                        >
                          {{
                            DOWNLOAD: (
                              <div>
                                {Array.isArray(get(log, header.value, "")) &&
                                  get(log, header.value, "").map((attachment, index) => (
                                    <IconButton
                                      key={index} // Use a unique key for each button
                                      style={{ width: "100%" }} // Make full width so each download button is on its own line
                                      onClick={(event) => {
                                        event.preventDefault();
                                        // Trigger the download for the specific attachment
                                        const link = document.createElement("a");
                                        link.href = attachment.url; // Set the URL of the file
                                        link.download = ""; // Optional: Set a default filename if needed
                                        link.target = "_blank"; // Open in a new tab to avoid popup blockers
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                      }}
                                    >
                                      <GetAppIcon />
                                    </IconButton>
                                  ))}
                              </div>
                            ),
                            "CREATED AT": (() => {
                              const { date, time } = formatDateTime(
                                get(log, header.value, "")
                              );
                              return (
                                <>
                                  {date}
                                  <br />
                                  {time}
                                </>
                              );
                            })(), // Use a self-invoking function to handle JSX for line break
                          }[header.label] || get(log, header.value, "")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          );
        })}
      </Table>
    </ThemeProvider>
  );
};

export default LogTable;
